<template>
  <div v-if="data" class="mt-5">
      <v-row v-if="!isSave">
        <v-col cols="3" sm="3" class="text-right" align-self="center"
          >タイプ</v-col
        >
        <v-col cols="9" sm="9">
          <v-select
            outlined
            dense
            hide-details="auto"
            :items="types"
            placeholder="未選択"
            v-model="data.type"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" sm="3" class="text-right" align-self="center"
          >イン</v-col
        >
        <v-col cols="9" sm="9">
          <v-dialog
            ref="clockin_dialog"
            v-model="modal.clock_in"
            :return-value.sync="data.clock_in"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                v-model="data.clock_in"
                dense
                :class="data.type != null ? '' : 'discls'"
                :disabled="data.type != null ? false : true"
                hide-details="auto"
                placeholder="未選択"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="modal.clock_in"
              v-model="data.clock_in"
              full-width
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal.clock_in = false"
                >キャンセル
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.clockin_dialog.save(data.clock_in)"
                >OK
              </v-btn>
            </v-time-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" sm="3" class="text-right" align-self="center"
          >アウト
        </v-col>
        <v-col cols="9" sm="9">
          <v-dialog
            ref="clockout_dialog"
            v-model="modal.clock_out"
            :return-value.sync="data.clock_out"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                v-model="data.clock_out"
                dense
                :class="isSave ? '' : 'discls'"
                :disabled="!isSave"
                hide-details="auto"
                placeholder="未選択"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="modal.clock_out"
              v-model="data.clock_out"
              full-width
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal.clock_out = false"
                >キャンセル</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="$refs.clockout_dialog.save(data.clock_out)"
                >OK</v-btn
              >
            </v-time-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" sm="3" class="text-right" align-self="center"
          >タグ
        </v-col>
        <v-col cols="9" sm="9">
          <v-select
            :class="isSave ? '' : 'discls'"
            outlined
            dense
            :items="allTags"
            :disabled="!isSave"
            item-value="id"
            item-text="name"
            hide-details
            placeholder="未選択"
            small-chips
            multiple
            v-model="data.tag"
          >
            <template v-slot:selection="{ attrs, item, select }">
              <v-chip
                v-bind="attrs"
                close
                @click="select"
                @click:close="removeTag(item.id)"
              >
                <v-icon small>mdi-tag </v-icon>
                <span>{{ item.name }}</span>
              </v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" sm="3" class="text-right">メモ</v-col>
        <v-col cols="9" sm="9">
          <v-textarea
            :class="isSave ? '' : 'discls'"
            outlined
            dense
            :disabled="!isSave"
            hide-details="auto"
            placeholder="自由入力"
            v-model="data.memo"
          ></v-textarea>
        </v-col>
      </v-row>

      <template v-if="data.resttime.length > 0 && isSave">
        <v-row
          v-for="(item, index) in data.resttime"
          :key="index"
          align="center"
        >
          <v-col cols="3" sm="3" class="text-right">{{ item.type.text }}</v-col>
          <v-col cols="6" sm="6">
            <v-text-field
              outlined
              dense
              :class="isSave ? '' : 'discls'"
              :disabled="!isSave"
              hide-details="auto"
              placeholder="未選択"
              @keypress="isNumber"
              v-model="item.minutes"
            ></v-text-field>
          </v-col>
          <v-col cols="3" sm="3">分</v-col>
        </v-row>
      </template>

      <v-row>
        <v-col cols="3"></v-col>
        <v-col cols="9" sm="9">
          <v-dialog v-model="modal.add_resttime" width="500" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                :disabled="!isSave ? true : false"
                :class="!isSave ? 'grey--text' : 'green--text font-weight-bold'"
              >
                ＋ 休憩を追加
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="justify-center"
                ><strong class="text-center">休憩を追加</strong></v-card-title
              >
              <v-card-text>
                <v-row>
                  <v-col cols="6" md="6">
                    <v-select
                      v-model="resttime.type"
                      :items="rest_types"
                      return-object
                      outlined
                      dense
                      hide-details="auto"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" md="6">
                    <div class="d-flex align-center">
                      <v-text-field
                        v-model="resttime.minutes"
                        outlined
                        dense
                        hide-details="auto"
                        @keypress="isNumber"
                        placeholder="0"
                      ></v-text-field>
                      <span class="ml-4 font-weight-bold text-md">分</span>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-btn
                      color="green darken-1"
                      class="white--text mt-5"
                      block
                      large
                      @click="addRestTime()"
                      >休憩を追加</v-btn
                    >
                    <v-btn
                      color="grey"
                      class="mt-5 mb-3"
                      block
                      text
                      @click="modal.add_resttime = false"
                      >キャンセル</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>

      <div v-if="data.clock_in" class="text-center mt-10 sticky-btn">
        <template v-if="!isSave">
          <v-btn @click="save" color="blue darken-2" class="white--text">
            <v-icon dense dark class="mr-2">mdi-timer </v-icon>
            勤怠をつくる
          </v-btn>
        </template>
        <template v-else>
          <div class="d-flex justify-center">
            <v-btn
              v-if="isEdit"
              text
              @click="remove"
              class="red--text align-center mr-10 py-2 px-3"
            >
              <v-icon dense dark class="mr-2 red--text">mdi-delete</v-icon>
              削除する
            </v-btn>

            <v-btn
              v-else
              @click="$router.push({ name: 'worktime' })"
              text
              class="align-center mr-10 py-2 px-3"
            >
              キャンセル
            </v-btn>
            <v-btn
              @click="saveWorkTime"
              color="blue darken-2"
              class="white--text"
            >
              保存する
            </v-btn>
          </div>
        </template>
      </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    data: {
      default: null,
    },
  },
  data() {
    return {
      isSave: false,
      types: [
        // { value: 0, text: "現場勤務（リ）", disabled: true },
        { value: 1, text: "現場勤務（個）" },
        { value: 2, text: "運転・調整" },
        // { value: 3, text: "参加" },
        // { value: 4, text: "タスク" },
        // { value: 5, text: "その他" },
      ],
      rest_types: [
        { text: "昼休憩", value: 0 },
        { text: "夜休憩", value: 1 },
      ],
      resttime: {
        type: null,
        minutes: null,
      },
      modal: {
        clock_in: false,
        clock_out: false,
        add_resttime: false,
      },
    };
  },
  computed: {
    ...mapGetters(["allTags"]),
  },

  created() {
    this.$store.dispatch("GET_ALL_TAGS")
    if (this.isEdit) this.isSave = true;
  },

  methods: {
    save() {
      this.isSave = true;
      this.$emit("hidelparent", this.isSave);
      return this.isSave;
    },

    getFormData(data) {
      let params = {};
      params.id = data.id;
      params.type_id = data.type;
      params.task_id = data.task.id;
      params.clock_in = data.clock_in;
      params.clock_out = data.clock_out;
      let dayRest = data.resttime.find((e) => e.type.value == "0");
      let nightRest = data.resttime.find((e) => e.type.value == "1");
      params.rest_hours_day = dayRest ? dayRest.minutes : null;
      params.rest_hours_night = nightRest ? nightRest.minutes : null;
      params.memo = data.memo;
      params.tags = data.tag;
      return params;
    },

    saveWorkTime() {
      let params = this.getFormData(JSON.parse(JSON.stringify(this.data)));
      let api = "";
      if (this.isEdit) api = "UPDATE_WORKTIME";
      else if (this.isSave) api = "CREATE_NEW_WORKTIME";
      this.$store.dispatch(api, params).then((result) => {
        this.loading = false;
        if (result.status === 200) {
          this.$router.push({
            name: "worktime",
          });
        }
      });
    },

    remove() {
      let id = this.data.id;
      this.$store.dispatch("DELETE_WORKTIME", id).then((result) => {
        this.loading = false;
        if (result.status === 200) {
          this.$router.push({
            name: "worktime",
          });
        }
      });
    },

    removeTag(item) {
      this.data.tag = this.data.tag.filter((tags) => tags !== item);
    },
    addRestTime() {
      let restData = this.data.resttime.filter((rest) => {
        if (JSON.stringify(rest.type) == JSON.stringify(this.resttime.type))
          return true;
      });
      if (restData.length > 0) {
        restData.map((obj) => {
          obj.minutes = parseInt(obj.minutes) + parseInt(this.resttime.minutes);
          return obj;
        });
      } else {
        this.data.resttime.push({
          type: this.resttime.type,
          minutes: this.resttime.minutes,
        });
      }
      this.resttime = {
        type: null,
        minutes: null,
      };
      this.modal.add_resttime = false;
    },
    isNumber(val) {
      if (isNaN(Number(val.key))) {
        return val.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
.discls{background: #ccc;}
.sticky-btn {
  position: sticky!important;
  bottom: 20px;
}
.theme--light.v-input,
.theme--light.v-input input {
  font-size: 12px;
}
</style>
